export const AnimatedSvgIds = {
  animatedSvg: 'animatedSvg',
  animatedTagPath: 'animatedPath',
  animateTagForward: 'animateForward',
  animateTagBackward: 'animateBackward',
};

export enum AnimationDirection {
  FORWARD = 'Forward',
  BACKWARD = 'Backward',
}

export const AnimatedDataAttributes = {
  [AnimationDirection.FORWARD]: 'data-animated-end-path',
  [AnimationDirection.BACKWARD]: 'data-animated-start-path',
};

export const TestIds = {
  animatedSvg: 'animated-svg',
  animateTagForward: 'animate-tag-forward',
  animateTagBackward: 'animate-tag-backward',
  fallbackPath: 'fallback-path',
};
