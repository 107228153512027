import type { SizingBehaviorPreset } from '@wix/editorx-core-api';

export const scalePreset: SizingBehaviorPreset<'Scale'> = {
  key: 'Scale',
  propsToApply: {
    componentLayout: {
      width: 'percentage',
      height: 'aspectRatio',
      minWidth: 'none',
      maxWidth: 'none',
      minHeight: 'none',
      maxHeight: 'none',
    },
  },
  propsToValidate: {
    componentLayout: [
      'width',
      'height',
      'minWidth',
      'maxWidth',
      'minHeight',
      'maxHeight',
    ],
  },
  isStyleScaleSupported: true,
};
