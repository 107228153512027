import type { DefaultCompPlatformProps } from '@wix/editor-elements-types/thunderbolt';
import type { CorvidTypes } from '@wix/editor-elements-types/corvid';
import type { ISiteButtonA11yProps } from '@wix/editor-elements-types/a11y';
import type {
  StylableButtonProps as StylableButtonPlatformProps,
  StylableButtonDefinition,
} from '@wix/thunderbolt-components';
import type {
  Direction,
  ControllerReportClickProps,
  ElementReportClickProps,
  MapperReportClickProps,
  MapperStateReportClickProps,
} from '@wix/editor-elements-common-utils';
import type {
  IFocusPropsSDKActions,
  IFocusPropsImperativeActions,
  IBorderRadiusSDK,
  IBorderWidthSDK,
  IClickPropSDK,
  IClickPropsSDKActions,
  IDisabledPropSDK,
  IElementPropsSDKActions,
  ILabelPropSDK,
  ILinkPropsSDK,
  IAccessibilityPropSDK,
  IAccessibilityPropSDKProps,
  IElementPropsSDK,
  ILinkPropsSDKProps,
  IBackgroundColorWithStateSDK,
  IBorderColorWithStatesSDK,
  ITextColorWithStatesSDK,
  IIconColorWithStatesSDK,
} from '@wix/editor-elements-corvid-utils';
import type { PickStateRefValues } from '@wix/editor-elements-integrations';

export {
  StylableButtonPlatformProps,
  StylableButtonPlatformProps as StylableButtonCarmiProps,
  StylableButtonDefinition,
};

export type StyleProps = IBackgroundColorWithStateSDK &
  IBorderColorWithStatesSDK &
  IBorderRadiusSDK &
  IBorderWidthSDK &
  IIconColorWithStatesSDK &
  ITextColorWithStatesSDK;

export type IStylableButtonProperties = {
  isMaxContent?: boolean;
  isWrapText?: boolean;
  iconCollapsed?: boolean;
};

export type IStylableButtonData = {
  link: string;
  svgId: string;
  label: string;
  direction: Direction;
};

export type IStylableButtonLayoutProperties = Omit<
  IStylableButtonProperties,
  'iconCollapsed'
>;

export interface IStylePropsSDK {
  style: Partial<StyleProps>;
}

export type IStylableButtonImperativeActions = IFocusPropsImperativeActions &
  IconAnimatedHandlers;

export type IStylableButtonCorvidStyleStateProps = {
  hasBackgroundColor?: boolean;
  hasBorderColor?: boolean;
  hasBorderRadius?: boolean;
  hasBorderWidth?: boolean;
  hasIconColor?: boolean;
  hasColor?: boolean;
};

export type IStylableButtonCorvidStateProps =
  IStylableButtonCorvidStyleStateProps & {
    hover?: IStylableButtonCorvidStyleStateProps;
    disabled?: IStylableButtonCorvidStyleStateProps;
    iconSvgString?: string | null;
    iconCollapsed?: boolean;
    iconAnimationTriggers?: Array<IconAnimationTriggers> | [];
  };

export type IStylableButtonMapperProps = DefaultCompPlatformProps & {
  a11y: ISiteButtonA11yProps;
  corvid?: IStylableButtonCorvidStateProps;
  ref?: React.Ref<IStylableButtonImperativeActions>;
  stylableButtonClassName?: string;
  semanticClassNames?: StylableButtonSemanticClassNames;
  isUdpExperimentOn?: boolean;
  compPreviewState?: string | null;
} & Partial<IStylableButtonEventHandlers> &
  StylableButtonPlatformProps &
  IStylableButtonProperties &
  IAccessibilityPropSDKProps &
  ILinkPropsSDKProps &
  Required<MapperReportClickProps> & {
    // should be removed whet StylableButton.ts in thunderbolt-components will be updated
    isIconAnimated?: boolean;
    svgAnimatedIcon?: string;
  } & {
    reducedMotion?: boolean;
  };

export interface StylableButtonAnimatedIcon {
  svgStringAnimatedStart: string;
  svgStringAnimatedEnd: string;
  svgStringAnimationSelected?: string;
  animationDuration: number;
}

export type IStylableButtonControllerProps =
  ControllerReportClickProps<IStylableButtonMapperProps>;

export type IStylableButtonProps =
  ElementReportClickProps<IStylableButtonControllerProps>;

export type IStylableButtonStateValues = PickStateRefValues<
  keyof MapperStateReportClickProps | 'reducedMotion'
>;

export type IStylableButtonEventHandlers = Partial<IElementPropsSDKActions> &
  Partial<IClickPropsSDKActions> &
  Partial<IFocusPropsSDKActions>;

// TODO - this is a nice translation from callback list to call back registering in SDK (reuse?)
export type IStylableButtonSDKCallbacks = {
  [CallbackName in keyof Partial<IStylableButtonEventHandlers>]: (
    cb: IStylableButtonEventHandlers[CallbackName],
  ) => void;
};

export type IStylableButtonOwnSDKFactory = CorvidTypes.CorvidSDKFactory<
  IStylableButtonProps,
  {},
  StylableButtonSdkData,
  IStylableButtonImperativeActions
>;

export type IStylableButtonSDK = IClickPropSDK &
  ILabelPropSDK &
  IDisabledPropSDK &
  ILinkPropsSDK &
  IAccessibilityPropSDK &
  IElementPropsSDK &
  IStylePropsSDK &
  IconAnimatedHandlers & {
    icon?: string;
  };

export type IStylableButtonSDKFactory = CorvidTypes.CorvidSDKFactory<
  IStylableButtonProps,
  IStylableButtonSDK
>;

export type IStylableButtonCorvidModel =
  CorvidTypes.ICorvidModel<IStylableButtonSDKFactory>;

export type StylableButtonSdkData = {
  svgId: string;
  mediaSvgUrl: string;
};

export type StylableButtonSemanticClassNames = {
  root: string;
  buttonIcon: string;
  buttonLabel: string;
};

export interface PhysicalToLogicalAlignment {
  left: LogicalAlignment;
  right: LogicalAlignment;
  center: LogicalAlignment;
  'space-between': LogicalAlignment;
}

export interface LogicalToPhysicalAlignment {
  'flex-start': PhysicalAlignment;
  'flex-end': PhysicalAlignment;
  center: PhysicalAlignment;
  'space-between': PhysicalAlignment;
}

export type LogicalAlignment =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between';
export type PhysicalAlignment = 'left' | 'right' | 'center' | 'space-between';
export type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type IStylableButtonOverflowCssVars = {
  '--label-text-overflow': React.CSSProperties['textOverflow'];
  '--label-white-space': React.CSSProperties['whiteSpace'];
  '--btn-min-width': React.CSSProperties['minWidth'];
};
export type TextAlignment = 'start' | 'end' | 'center' | 'justify';

export interface TextualToLogicalAlignment {
  start: LogicalAlignment;
  end: LogicalAlignment;
  center: LogicalAlignment;
  justify: LogicalAlignment;
}

export interface LogicalToTextualAlignment {
  'flex-start': TextAlignment;
  'flex-end': TextAlignment;
  center: TextAlignment;
  'space-between': TextAlignment;
}

export enum IconAnimationTriggers {
  HOVER = 'hover',
}

export type IconAnimatedHandlers = {
  animateIconForward?: () => void;
  animateIconBackward?: () => void;
};
