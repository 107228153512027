import React, {
  forwardRef,
  useRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import type { AnimatedSvgRef, AnimatedSvgProps } from '../AnimatedSvg.types';
import {
  AnimatedSvgIds,
  AnimationDirection,
  AnimatedDataAttributes,
} from '../constants';

const AnimatedSvg = forwardRef<AnimatedSvgRef, AnimatedSvgProps>(
  ({ svgContent, reducedMotion = false }, ref) => {
    const svgContainerRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => ({
      runAnimationForward,
      runAnimationBackward,
    }));

    const runAnimation = useCallback(
      (animationDirection: AnimationDirection) => {
        if (!svgContainerRef.current) {
          return;
        }
        if (!reducedMotion) {
          const animatedTag =
            `animateTag${animationDirection}` as keyof typeof AnimatedSvgIds;
          const animateTags =
            svgContainerRef.current.querySelectorAll<SVGAnimateElement>(
              `[data-animate-id=${AnimatedSvgIds[animatedTag]}]`,
            );
          animateTags.forEach(el => el.beginElement());
        }

        const pathTags = svgContainerRef.current.querySelectorAll(
          `path[data-animate-id=${AnimatedSvgIds.animatedTagPath}]`,
        );
        // The final shape of the animation does not always match the defined end shape exactly.
        // Therefore, it is necessary to manually switch to the end shape
        // while preserving the start shape to use it in the opposite direction.
        pathTags.forEach(path => {
          const currentD = path.getAttribute('d');
          const animatedEndPath = path.getAttribute(
            AnimatedDataAttributes[animationDirection],
          );
          if (animatedEndPath) {
            const oppositeDirection =
              animationDirection === AnimationDirection.FORWARD
                ? AnimationDirection.BACKWARD
                : AnimationDirection.FORWARD;
            if (!path.getAttribute(AnimatedDataAttributes[oppositeDirection])) {
              path.setAttribute(
                AnimatedDataAttributes[oppositeDirection],
                currentD || '',
              );
            }
            path.setAttribute('d', animatedEndPath);
          }
        });
      },
      [reducedMotion],
    );

    const runAnimationForward = useCallback(() => {
      runAnimation(AnimationDirection.FORWARD);
    }, [runAnimation]);

    const runAnimationBackward = useCallback(() => {
      runAnimation(AnimationDirection.BACKWARD);
    }, [runAnimation]);

    return (
      <div
        ref={svgContainerRef}
        dangerouslySetInnerHTML={{ __html: svgContent }}
      />
    );
  },
);

export default AnimatedSvg;
